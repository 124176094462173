import React, { useState } from "react";
import { Heading, TextInput } from "../reusable";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import commissionImg from "../../images/commissions.png";
import Select from "react-select";
import { motion } from "framer-motion";

function Form({ item }) {
    // State variables for form inputs
    const [entry378494427, setEntry378494427] = useState("");
    const [entry1818321937, setEntry1818321937] = useState("");
    const [entry1707772383, setEntry1707772383] = useState("");
    const [entry1381603031, setEntry1381603031] = useState("");
    const [entry1504533932, setEntry1504533932] = useState("");

    // Form URL
    const url =
        "https://docs.google.com/forms/d/e/1FAIpQLSe1GAq61Kdluh5r1oD0uZ1uD9mYdjMyOCrDlDInxQVUEzCzfQ/formResponse"; //action url

    // Submit form function
    function submitForm(evt) {
        evt.preventDefault();

        if (
            entry1707772383.value !== undefined &&
            entry1381603031.value !== undefined
        ) {
            // Send form data
            fetch(url, {
                method: "POST",
                mode: "no-cors",
                header: {
                    "Content-Type": "application/json",
                },
                body: getInputData(), // Gets input data from state variables
            }).catch((err) => console.error(err)); //promise based

            // Sends toast
            toast.success("Form submitted!", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                closeButton: false,
            });

            // Reset form entries
            setEntry378494427("");
            setEntry1818321937("");
            setEntry1707772383("");
            setEntry1381603031("");
            setEntry1504533932("");

            // Reset text input elements
            document.getElementById("entry.378494427").value = "";
            document.getElementById("entry.1818321937").value = "";
            document.getElementById("entry.1504533932").value = "";
        } else {
            // Sends error toast
            toast.error(
                "Error submitting form! Make sure you have filled out all fields correctly.",
                {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    closeButton: false,
                }
            );
        }

        //populating input data
        function getInputData() {
            let dataToPost = new FormData(); //formdata API

            dataToPost.append("entry.378494427", entry378494427);
            dataToPost.append("entry.1818321937", entry1818321937);
            dataToPost.append("entry.1707772383", entry1707772383);
            dataToPost.append("entry.1381603031", entry1381603031);
            dataToPost.append("entry.1504533932", entry1504533932);

            return dataToPost;
        }
    }

    return (
        <div className="flex flex-col items-center justify-center align-middle p-2 m-2 w-full sm:w-4/5 lg:w-1/2 md:2/3">
            <motion.img
                variants={item}
                src={commissionImg}
                className="flex flex-col rounded-3xl shadow-lg"
            />
            <motion.form
                variants={item}
                onSubmit={submitForm}
                className="flex flex-col w-full mt-8 bg-white dark:bg-darkThemeGrey text-gray-900 dark:text-gray-100 shadow-lg rounded-3xl p-12"
                id="form"
            >
                <Heading text={"Commissions Form:"} />

                <div className="md:grid md:grid-cols-2 md:gap-5 mt-4">
                    <TextInput
                        label="Name and preferred pronouns:"
                        name="entry.378494427"
                        type="text"
                        setter={(e) => setEntry378494427(e.target.value)}
                    />
                    <TextInput
                        label="Contact email: "
                        name="entry.1818321937"
                        type="email"
                        setter={(e) => setEntry1818321937(e.target.value)}
                    />
                </div>

                <div className="grid grid-cols-2 gap-5 mt-6">
                    <div>
                        <label>Size: </label>
                        <Select
                            value={entry1707772383}
                            className="grid mt-2 text-gray-900"
                            onChange={setEntry1707772383}
                            options={[
                                { value: "Bust", label: "Bust" },
                                {
                                    value: "Bust and torso",
                                    label: "Bust and torso",
                                },
                                { value: "Full body", label: "Full body" },
                            ]}
                        />
                    </div>

                    <div>
                        <label>Quality: </label>
                        <Select
                            value={entry1381603031}
                            className="grid mt-2 text-gray-900"
                            onChange={setEntry1381603031}
                            options={[
                                { value: "Sketch", label: "Sketch" },
                                { value: "Colour", label: "Colour" },
                                { value: "Render", label: "Render" },
                            ]}
                        />
                    </div>
                </div>

                <label
                    for="entry.1504533932"
                    className="mt-10 pb-1 text-gray-900 dark:text-gray-100"
                >
                    Brief Drawing Description - More detail can be given at a
                    later point:{" "}
                </label>
                <textarea
                    required
                    className="transition duration-300 border-none w-full h-16 px-3 py-2 text-base text-gray-700 bg-gray-100 border shadow-md rounded-md focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                    type="text"
                    name="entry.1504533932"
                    id="entry.1504533932"
                    onChange={(e) => setEntry1504533932(e.target.value)}
                ></textarea>
                <div className="flex flex-row items-center justify-center">
                    <input
                        type="submit"
                        className="cursor-pointer shadow-md bg-gray-100 transition duration-300 ease-in-out hover:bg-primary hover:text-white transform hover:-translate-y-0.5 px-5 py-3 mt-8 text-m rounded-lg font-medium text-gray-800"
                        value="Submit"
                    />
                </div>
            </motion.form>
        </div>
    );
}

function Commissions({ container, item }) {
    return (
        <motion.div
            initial="hidden"
            variants={container}
            exit="hidden"
            animate="show"
            className="main-content flex flex-col justify-center items-center"
        >
            <div className="flex flex-row">
                <Heading item={item} text="Commissions!" />
            </div>

            <Form item={item} />

            <ToastContainer
                toastClassName="bg-white dark:bg-darkThemeGrey shadow-lg flex justify-between relative rounded-lg overflow-hidden cursor-pointer m-4 md:m-0 p-4"
                bodyClassName="text-sm text-gray-900 dark:text-gray-100 font-med flex"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </motion.div>
    );
}

export default Commissions;
