import React from "react";
import { Link, useLocation } from "react-router-dom";

export default function NavLink({ name, link, mobile }) {
    // Gets current location
    const location = useLocation();

    let mobileStyles =
        "text-gray-900 dark:text-gray-100 dark:bg-darkThemeGrey transition hover:bg-primary hover:text-white block px-3 py-2 rounded-md text-base font-medium";
    let desktopStyles =
        location.pathname === link
            ? "bg-primary text-white -translate-y-0.5 px-3 py-2 rounded-lg text-m font-medium shadow-lg transition duration-300 ease-in-out"
            : "bg-gray-100 dark:bg-black transition duration-300 ease-in-out hover:bg-primary dark:hover:bg-primary hover:text-white dark:hover:text-white transform hover:-translate-y-0.5 px-3 py-2 text-m rounded-lg font-medium text-gray-800 dark:text-gray-100";

    let classes = mobile ? mobileStyles : desktopStyles;

    return (
        <Link className={classes} to={link}>
            {name}
        </Link>
    );
}
