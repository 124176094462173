import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PrivateRoute from './components/routes/PrivateRoute';
import { Wallpapers, Home, About, Commissions, Admin, Login, NotFound, Donate, Portfolio } from './components/pages';
import { Footer, Navigation } from './components/layouts';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faUpload } from '@fortawesome/free-solid-svg-icons'
import { AnimatePresence } from 'framer-motion';

library.add(fab, faUpload);

// Animation
const container = {
  hidden: { y: -40, opacity: 0 },
  show: {
    y: 0,
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
      duration: 0.5,
    }
  }
}

const item = {
  hidden: { y: -40, opacity: 0 },
  show: { y: 0, opacity: 1, transition: {duration: 0.5} }
}

export default function App() {

  return (
    <div className="min-h-screen transition duration-300 bg-gray-100 dark:bg-black text-gray-800 dark:text-white justify-center">
      <Router>
        <Navigation />
          <AnimatePresence>
            <Switch>
              <Route path="/" exact component={() => <Home container={container} item={item} />} />
              <Route path="/wallpapers" exact component={() => <Wallpapers container={container} item={item} />} />
              <Route path="/about" exact component={() => <About container={container} item={item} />} />
              <Route path="/commissions" exact component={() => <Commissions container={container} item={item} />} />
              <Route path="/donate" exact component={() => <Donate container={container} item={item} />} />
              <Route path="/portfolio" exact component={() => <Portfolio container={container} item={item} />} />
              <Route path="/login" exact component={() => <Login container={container} item={item}/>} />
              <PrivateRoute path="/admin" exact component={() => <Admin container={container} item={item} />} />
              <Route exact component={() => <NotFound container={container} item={item} />} />
            </Switch>
          </AnimatePresence>
        <Footer />
      </Router>
    </div>
  );
}