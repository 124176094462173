import React from "react";
import Upload from "./Upload";
import { Heading, Card, SmallHeading } from "../reusable";
import { motion } from "framer-motion";
import { useAuth } from "../../hooks/useAuth";
import { useHistory } from "react-router-dom";

export default function Admin({ container, item }) {
    const { user, logout } = useAuth();
    const history = useHistory();

    async function handleLogout() {
        await logout();
        history.push("/login");
    }

    return (
        <motion.div
            initial="hidden"
            variants={container}
            exit="hidden"
            animate="show"
            className="flex flex-col m-16 justify-center items-center align-middle"
        >
            <Heading text="Admin Page" />
            <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-8">
                <Card item={item}>
                    <SmallHeading text="Upload Wallpapers" />
                    <Upload />
                </Card>
                <Card item={item}>
                    <SmallHeading text="Account Information" />
                    <p>Email: {user.email}</p>
                    <button
                        onClick={handleLogout}
                        className="cursor-pointer shadow-md bg-gray-100 transition duration-300 ease-in-out hover:bg-primary hover:text-white transform hover:-translate-y-0.5 px-5 py-3 mt-8 text-m rounded-lg font-medium text-gray-800"
                    >
                        Log Out
                    </button>
                </Card>
            </div>
        </motion.div>
    );
}
