import React from "react";
import Heading from "../reusable/Heading";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

export default function NotFound({ container, item }) {
    return (
        <motion.div
            initial="hidden"
            variants={container}
            exit="hidden"
            animate="show"
            className="flex justify-center transition duration-300 ease-in-out"
        >
            <div className="bg-white dark:bg-darkThemeGrey dark:text-gray-100 shadow-lg rounded-lg p-12">
                <Heading item={item} text="404: Page not Found" />
                <motion.p className="my-8" variants={item}>
                    Whoops! The page you requested does not exist...
                </motion.p>
                <Link
                    className="bg-primary text-white transform ease-in-out hover:-translate-y-0.5 px-3 py-2 text-m rounded-lg font-medium shadow-md"
                    to={"/"}
                >
                    Go to homepage
                </Link>
            </div>
        </motion.div>
    );
}
