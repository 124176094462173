import React from "react";
import { motion } from "framer-motion";
import "./home.css";

function TitleSection({ text, item }) {
    return (
        <h1 className="transition flex flex-row cover-heading tracking-widest uppercase font-black overflow-hidden text-transparent justify-center">
            <motion.span variants={item} className="heading">
                {text}
            </motion.span>
        </h1>
    );
}

function Home({ container, item }) {
    return (
        <motion.div
            initial="hidden"
            variants={container}
            exit="hidden"
            animate="show"
            className="flex flex-col justify-center items-center main-content align-middle mx-2"
        >
            <TitleSection text="mollsk" item={item} />
            <TitleSection text="etches" item={item} />
        </motion.div>
    );
}

export default Home;
