import { motion } from "framer-motion";
import React from "react";

export default function Heading({ item, text }) {
    return (
        <motion.h1
            variants={item}
            className="text-4xl sm:text-5xl md:text-6xl font-bold tracking-wide mb-8"
        >
            {text}
        </motion.h1>
    );
}
