import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSun, faMoon } from "@fortawesome/free-solid-svg-icons";
import { ThemeContext, themes } from "../../contexts/themeContext";

export default function ThemeToggle() {
    // Dark mode state
    const [darkMode, setDarkMode] = useState(true);

    return (
        <ThemeContext.Consumer>
            {({ changeTheme }) => (
                <button
                    color="link"
                    onClick={() => {
                        setDarkMode(!darkMode);
                        changeTheme(darkMode ? themes.light : themes.dark);
                    }}
                >
                    <FontAwesomeIcon icon={darkMode ? faSun : faMoon} />
                </button>
            )}
        </ThemeContext.Consumer>
    );
}
