import React from "react";
import { Heading } from "./../reusable";
import { motion } from "framer-motion";

export default function Donate({ container, items }) {
    return (
        <motion.div
            initial="hidden"
            variants={container}
            exit="hidden"
            animate="show"
            className="flex justify-center transition duration-300 ease-in-out"
        >
            <Heading text={"Donate"} />
            <a
                className="bg-primary text-white -translate-y-0.5 px-3 py-2 rounded-lg text-m font-medium shadow-lg transition duration-300 ease-in-out"
                href={
                    "https://www.paypal.com/donate/?hosted_button_id=WF7B64YW9EK5L"
                }
            >
                Donate!
            </a>
        </motion.div>
    );
}
