import { motion } from "framer-motion";
import React from "react";

export default function SmallHeading({ item, text }) {
    return (
        <motion.h2
            variants={item}
            className="text-2xl sm:text-3xl md:text-4xl font-bold tracking-wide mb-8"
        >
            {text}
        </motion.h2>
    );
}
