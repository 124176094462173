import React from "react";
import { Heading, Card } from "../reusable";
import { motion } from "framer-motion";

export default function About({ container, item }) {
    return (
        <motion.div
            initial="hidden"
            variants={container}
            exit="hidden"
            animate="show"
            className="flex flex-col justify-center items-center"
        >
            <Heading item={item} text="About me :)" />
            <div className="text-gray-600 text-lg lg:mx-20 grid md:grid-cols-2 gap-4 my-6 mx-8">
                <Card item={item}>
                    {" "}
                    Mollie aka Mollsketches is an 18 year old amateur
                    illustrator and artist. She is beginning her studies at a
                    prestigious art school in Scotland in 2021, Illustration
                    (BA/Hons).
                </Card>
                <Card item={item}>
                    {" "}
                    Mollie has gained a mediocre following on platforms such as
                    Twitter, Instagram, YouTube and Twitch. There they mostly
                    post Fanart from various Fandoms and of artists such as;
                    MCYT and Orla Gartland. This work has been recognised by the
                    likes of dodie, Orla Gartland and popular content creator
                    Dream.
                </Card>
                <Card item={item}>
                    {" "}
                    She has more real life experience with commissions for
                    education and scouting associations where they have created
                    designs for posters, thumbnails and badges.
                </Card>
                <Card item={item}>
                    {" "}
                    Whilst Mollie is still learning, she is a very capable and
                    friendly individual and can be contacted through this form
                    if you'd like to commission her!
                </Card>
            </div>
        </motion.div>
    );
}
