import React, { useState } from "react";
import { Heading, TextInput } from "../reusable";
import { motion } from "framer-motion";
import { useAuth } from "../../hooks/useAuth";
import { useHistory } from "react-router-dom";

function Login({ container, item }) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const { user, signIn } = useAuth();
    const history = useHistory();

    if (user) {
        history.push("/admin");
    }

    async function SubmitForm(evt) {
        // Prevents the default
        evt.preventDefault();

        await signIn(email, password);
        history.push("/admin");
    }

    return (
        <motion.div
            initial="hidden"
            variants={container}
            exit="hidden"
            animate="show"
            className="flex flex-col justify-center items-center"
        >
            <Heading item={item} text="Admin Login" />
            {user && user.email}
            <motion.form
                variants={item}
                onSubmit={SubmitForm}
                className="flex flex-col w-full mt-8 bg-white dark:bg-darkThemeGrey text-gray-900 dark:text-gray-100 shadow-lg rounded-3xl p-12"
                id="form"
            >
                <TextInput
                    label="Email:"
                    name="email"
                    type="email"
                    setter={setEmail}
                />
                <TextInput
                    label="Password: "
                    name="password"
                    type="password"
                    setter={setPassword}
                />
                <div className="flex flex-row items-center justify-center">
                    <input
                        type="submit"
                        className="cursor-pointer shadow-md bg-gray-100 transition duration-300 ease-in-out hover:bg-primary hover:text-white transform hover:-translate-y-0.5 px-5 py-3 mt-8 text-m rounded-lg font-medium text-gray-800"
                        value="Submit"
                    />
                </div>
            </motion.form>
        </motion.div>
    );
}

export default Login;
