import React, { useState, useEffect } from "react";
import { storage } from "../../Firebase";
import { ref, getDownloadURL, listAll, getMetadata } from "firebase/storage";
import { Heading, Loading } from "../reusable";
import { motion } from "framer-motion";

function WallpaperGrid({ container, item }) {
    const [wallpapers, setWallpapers] = useState(null);

    useEffect(() => {
        const wallpaperRef = ref(storage, "wallpapers/thumb");
        listAll(wallpaperRef).then(async (res) => {
            setWallpapers(res.items);
        });
    }, []);

    return (
        <motion.div
            initial="hidden"
            variants={container}
            exit="hidden"
            animate="show"
            className="w-full"
        >
            <div className="flex justify-center">
                <div className="flex flex-col items-center">
                    <Heading item={item} text="Wallpapers" />
                    <motion.p
                        variants={item}
                        className="flex md:mb-4 text-gray-700 dark:text-gray-100"
                    >
                        Here is a collection of my phone wallpapers. Click to
                        download!
                    </motion.p>
                    {/* <motion.p variants={item} className="flex md:mb-4 text-gray-700 dark:text-gray-100">Click to download!</motion.p> */}
                </div>
            </div>
            <div className="w-full flex justify-center">
                {wallpapers ? (
                    <div className="grid m-8 gap-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                        {wallpapers.map((wallpaper, i) => (
                            <WallpaperCard
                                item={item}
                                key={i}
                                imageRef={wallpaper}
                            />
                        ))}
                    </div>
                ) : (
                    <motion.div className="main-content" item={item}>
                        <Loading />
                    </motion.div>
                )}
            </div>
        </motion.div>
    );
}

function WallpaperCard({ item, imageRef }) {
    const [url, setUrl] = useState(null);
    const [name, setName] = useState(null);
    const [download, setDownload] = useState(null);

    // const refPlaceholder = useRef();

    // const removePlaceholder = () => {
    //     refPlaceholder.current.remove();
    // };

    useEffect(() => {
        getDownloadURL(imageRef).then((url) => {
            setUrl(url);
        });
    }, [imageRef]);

    // TODO: Rewrite below functions (must be a better way of doing it)

    async function getOriginalName(imageRef) {
        return getMetadata(imageRef).then((metadata) => {
            var originalNamePromise = metadata.customMetadata.originalName;
            return originalNamePromise;
        });
    }

    getOriginalName(imageRef).then((originalNamePromise) => {
        var originalName = originalNamePromise;
        setName(originalName);
    });

    async function getAmazingDownloadURL(imageRef) {
        return getMetadata(imageRef).then((metadata) => {
            var amazingDownloadURLPromise =
                metadata.customMetadata.amazingDownloadURL;
            return amazingDownloadURLPromise;
        });
    }

    getAmazingDownloadURL(imageRef).then((amazingDownloadURLPromise) => {
        var amazingDownloadURL = amazingDownloadURLPromise;
        setDownload(amazingDownloadURL);
    });

    // function Placeholder({ item }) {
    //     return (
    //         <div className="p-2 cursor-pointer bg-white dark:bg-darkThemeGrey rounded-lg shadow-lg transition duration-300 ease-in-out">
    //             <div className="imagesize"><Loading/></div>
    //         </div>
    //     )
    // }

    function downloadImage(url, filename) {
        var fileDownload = require("js-file-download");
        const axios = require("axios");

        axios
            .get(url, {
                responseType: "blob",
            })
            .then((res) => {
                fileDownload(res.data, filename);
            });
    }

    return (
        <div>
            {/* <Placeholder height="100%" minHeight="600px" item={item} ref={refPlaceholder}/>*/}
            {/* <LazyLoad height="600px" offset="100px" once> */}
            <div
                onClick={() => downloadImage(download, name)}
                className="p-2 cursor-pointer bg-white dark:bg-darkThemeGrey rounded-lg shadow-lg transition duration-300 ease-in-out transform hover:scale-105"
            >
                <img
                    height="600px"
                    width="337px"
                    alt={name}
                    src={url}
                    item={item}
                ></img>
            </div>
            {/* </LazyLoad> */}
        </div>
    );
}

export default WallpaperGrid;
