import React from "react";

export default function TextInput({ label, name, type, setter }) {
    return (
        <div className="grid">
            <label className="mb-2 text-gray-900 dark:text-gray-100">
                {label}
            </label>
            <input
                className="mb-4 transition duration-300 px-2 bg-gray-100 text-gray-700 rounded-md shadow-md h-9 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                type={type}
                name={name}
                onChange={(e) => setter(e.target.value)}
                required
            />
        </div>
    );
}
