import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { Transition } from "@headlessui/react";
import profileImg from "../../images/profile.png";
import { ThemeToggle, NavLink } from "../reusable";

function Navigation() {
    const links = [
        { name: "Home", link: "/" },
        { name: "About", link: "/about" },
        { name: "Portfolio", link: "/portfolio" },
        { name: "Wallpapers", link: "/wallpapers" },
        { name: "Commissions", link: "/commissions" },
        { name: "Donate", link: "/donate" },
    ];

    // State variable for mobile navigation menu
    const [isOpen, setIsOpen] = useState(false);

    return (
        <nav className="py-4">
            <div className="max-w-7xl mx-auto px-6 sm:px-6 lg:px-8 py-2 align-middle items-center">
                <div className="flex items-center align-middle justify-between h-24 w-full">
                    <div className="flex items-center w-full justify-between">
                        <div>
                            <div className="flex justify-center align-middle items-center">
                                <Link
                                    className="flex justify-center align-middle items-center"
                                    to={"/"}
                                >
                                    <img
                                        className="h-16 w-16"
                                        src={profileImg}
                                        alt="Mollsketches Icon"
                                    />
                                    <h1 className="text-2xl font-semibold pl-6 tracking-wide text-gray-800 dark:text-gray-100">
                                        Mollsketches
                                    </h1>
                                </Link>
                            </div>
                        </div>
                        <div className="hidden md:flex justify-end items-center">
                            <div className="ml-10 flex items-baseline space-x-4 justify-end">
                                {links.map(({ name, link }) => (
                                    <NavLink name={name} link={link} />
                                ))}
                                <ThemeToggle />
                            </div>
                        </div>
                    </div>
                    <div className="-mr-2 flex md:hidden">
                        <ThemeToggle />
                        <button
                            onClick={() => setIsOpen(!isOpen)}
                            onBlur={() => setIsOpen(false)}
                            type="button"
                            className="inline-flex items-center justify-center p-2 rounded-md text-black dark:text-gray-100"
                            aria-controls="mobile-menu"
                            aria-expanded="false"
                        >
                            <span className="sr-only">Open main menu</span>
                            {!isOpen ? (
                                <svg
                                    className="block h-6 w-6"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M4 6h16M4 12h16M4 18h16"
                                    />
                                </svg>
                            ) : (
                                <svg
                                    className="block h-6 w-6"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            )}
                        </button>
                    </div>
                </div>
            </div>

            <Transition
                show={isOpen}
                enter="transition ease-out duration-100 transform"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in duration-75 transform"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                {(ref) => (
                    <div className="md:hidden" id="mobile-menu">
                        <div
                            ref={ref}
                            className="px-2 pt-2 pb-3 space-y-1 sm:px-3 bg-white dark:bg-darkThemeGrey shadow m-4 rounded-lg"
                        >
                            {links.map(({ name, link }) => (
                                <NavLink name={name} link={link} mobile />
                            ))}
                        </div>
                    </div>
                )}
            </Transition>
        </nav>
    );
}

export default withRouter(Navigation);
