import { motion } from "framer-motion";
import React from "react";

export default function Card({ item, children }) {
    return (
        <motion.div
            variants={item}
            className="transition duration-300 bg-white dark:bg-darkThemeGrey dark:text-gray-100 shadow-lg rounded-lg p-12"
        >
            {children}
        </motion.div>
    );
}
