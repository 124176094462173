import React, { useState } from "react";
import { storage } from "../../Firebase";
import {
    getDownloadURL,
    ref,
    uploadBytesResumable,
    uploadString,
} from "firebase/storage";
import Resizer from "react-image-file-resizer";

function Upload() {
    const [files, setFiles] = useState([]);

    const onFileChange = (e) => {
        for (let i = 0; i < e.target.files.length; i++) {
            const newFile = e.target.files[i];
            newFile["id"] = Math.random();
            // add an "id" property to each File object
            setFiles((prevState) => [...prevState, newFile]);
        }
    };

    const resizeFile = (file, downloadURL) =>
        new Promise(() => {
            Resizer.imageFileResizer(
                file,
                600,
                600,
                "WEBP",
                80,
                0,
                (uri) => {
                    const thumbMetadata = {
                        customMetadata: {
                            isThumb: "true",
                            originalName: file.name,
                            amazingDownloadURL: downloadURL,
                        },
                    };

                    const storageRef = ref(
                        storage,
                        "wallpapers/thumb/" + file.name + "_thumb"
                    );
                    uploadString(storageRef, uri, "data_url", thumbMetadata);
                },
                "base64"
            );
        });

    const onUploadSubmission = (e) => {
        e.preventDefault(); // prevent page refreshing
        files.forEach(async (file) => {
            const metadata = {
                customMetadata: {
                    isThumb: "false",
                    originalName: file.name,
                },
            };

            const storageRef = ref(storage, "wallpapers/" + file.name);
            const uploadTask = await uploadBytesResumable(
                storageRef,
                file,
                metadata
            );

            var downloadURL = await getDownloadURL(uploadTask.ref);

            await resizeFile(file, downloadURL);
        });
    };

    const inputfilestyle = {
        width: "0.1px",
        height: "0.1px",
        opacity: "0",
        overflow: "hidden",
        position: "absolute",
        zIndex: "-1",
    };

    return (
        <form className="flex flex-col items-center justify-center align-middle">
            <label className="mb-4 cursor-pointer bg-gray-100 dark:bg-darkThemeGrey transition duration-300 ease-in-out hover:bg-primary dark:hover:bg-primary hover:text-white dark:hover:text-white transform hover:-translate-y-0.5 px-3 py-2 text-m rounded-lg font-medium text-gray-800 dark:text-gray-100">
                Select Files
                <input
                    type="file"
                    onChange={onFileChange}
                    style={inputfilestyle}
                    multiple
                />
            </label>
            <button
                className="bg-gray-100 dark:bg-darkThemeGrey transition duration-300 ease-in-out hover:bg-primary dark:hover:bg-primary hover:text-white dark:hover:text-white transform hover:-translate-y-0.5 px-3 py-2 text-m rounded-lg font-medium text-gray-800 dark:text-gray-100"
                onClick={onUploadSubmission}
            >
                Upload
            </button>
        </form>
    );
}

export default Upload;
