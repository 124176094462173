import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function SocialButton({ name, link, icon }) {
    return (
        <a
            href={link}
            target="blank_"
            title={name}
            className="px-5 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110 text-gray-800 dark:text-gray-100"
        >
            <FontAwesomeIcon icon={["fab", icon]} />
        </a>
    );
}

export default function SocialButtons() {
    const socialMediaLinks = [
        {
            name: "Twitter",
            link: "https://www.twitter.com/mollsketches",
            icon: "twitter",
        },
        {
            name: "Instagram",
            link: "https://www.instagram.com/mollsketches",
            icon: "instagram",
        },
        {
            name: "TikTok",
            link: "https://www.tiktok.com/@mollsketches",
            icon: "tiktok",
        },
        {
            name: "Twitch",
            link: "https://twitch.tv/mollsketches",
            icon: "twitch",
        },
        {
            name: "YouTube",
            link: "https://www.youtube.com/channel/UCyMk3CAXUzol7J-0Hdq7PYQ",
            icon: "youtube",
        },
    ];

    return (
        <div className="flex py-4 w-min justify-center px-2">
            {socialMediaLinks.map((link) => (
                <SocialButton
                    name={link.name}
                    link={link.link}
                    icon={link.icon}
                />
            ))}
        </div>
    );
}
