import React from "react";

export default function Loading() {
    return (
        <div className="flex mx-4 py-4 mb-4 flex-grow justify-center items-center align-middle">
            <div
                style={{ borderTopColor: "transparent" }}
                className="flex spinner w-7 h-7 border-4 border-secondary border-solid rounded-full animate-spin"
            ></div>
        </div>
    );
}
