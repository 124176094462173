import React, { useState, useEffect, useContext, createContext } from 'react';
import { signInWithEmailAndPassword, signOut, onAuthStateChanged, updatePassword } from "firebase/auth";
import { auth } from "../Firebase";

const AuthContext = createContext();

// Hook for child components to get the auth object...
// ... and re-render when it changes
export const useAuth = () => {
    return useContext(AuthContext);
}

// Provider hook that creates auth object and handles state
export const AuthProvider = ({children}) => {
    const [user, setUser] = useState(null);
    const [isAuthenticating, setIsAuthenticating] = useState(true);

    const signIn = (email, password) => {
        return signInWithEmailAndPassword(auth,email,password).then(result => {
            setUser(result.user);
            return true;
        })
    }

    const logout = () => {
        return signOut(auth,user).then(() => {
            setUser(null);
        })
    }

    const plsUpdatePassword = (password) => {
        return updatePassword(user,password);
    }

    // Subscribe to user on mount
    // Because this sets state in the callback it will cause any...
    // ... component that utilizes this hook to re-render with the latest auth object.
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setUser(user);
            setIsAuthenticating(false);
        })

        // Cleanup subscription on unmount
        return () => unsubscribe()

    },[])

    const values = {
        user,
        isAuthenticating,
        signIn,
        logout,
        plsUpdatePassword
    }

    return (
        <AuthContext.Provider value={values}>
            {!isAuthenticating && children}
        </AuthContext.Provider>
    )

}