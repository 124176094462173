import React from "react";
import profileIcon from "../../images/profile.png";
import { SocialButtons, FooterLink } from "../reusable";

export default function Footer() {
    const d = new Date();
    const currentYear = d.getFullYear();

    return (
        <footer className="footer--pin max-w-7xl mx-auto flex flex-col pb-10 md:mt-12 justify-center items-center">
            <div className="flex justify-center pb-4 mt-4 pt-6 align-middle">
                <img
                    className="h-16 w-16"
                    alt="Mollsketches profile"
                    src={profileIcon}
                />
                <h1 className="flex px-4 py-4 justify-center text-xl font-semibold tracking-wide text-gray-800 dark:text-gray-100">
                    Mollsketches
                </h1>
            </div>
            <SocialButtons />
            <p className="text-md py-2 text-gray-600 dark:text-gray-300">
                Contact:{" "}
                <FooterLink
                    name="contact@mollsketches.art"
                    link="mailto:contact@mollsketches.art"
                />{" "}
                | Created By:{" "}
                <FooterLink name="David Beechey" link="https://dtbeechey.dev" />
            </p>
            <p className="text-sm py-2 text-gray-600 dark:text-gray-300">
                &copy; Copyright {currentYear} Mollsketches. All Rights Reserved
            </p>
        </footer>
    );
}
