import React from "react";
import "./footerlink.css";

export default function FooterLink({ name, link }) {
    return (
        <a className="fancy-underline dark:text-gray-100" href={link}>
            {name}
        </a>
    );
}
